import { Redirect, Route, Switch } from 'react-router-dom';
import {Component, useEffect} from "react";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
  IonModal,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { cloudyNightOutline, square, peopleOutline, wineOutline } from 'ionicons/icons';
import HomeScreen from './pages/HomeScreen';
import ItineraryView from "./pages/ItineraryView/ItineraryView";
import LoginScreen from "./pages/LoginScreen";
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';
import {isUserLoggedIn, login} from './services/user.service';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import NewDateScreen from "./pages/NewDateScreen/NewDateScreen";
import userService from "./services/user.service";
import SignupScreen from "./pages/SignupScreen";
import Builder from "./pages/Builder/Builder";

setupIonicReact();

const ProtectedRoute: any = (props: any) => {
    const { component: Component, children, ...rest } = props;
    let isLoggedIn = userService.isUserLoggedIn();
    useEffect(() => {
      isLoggedIn = userService.isUserLoggedIn();
    });
    const renderComponent = isLoggedIn ?
        <Component {...props} /> :
        <Redirect
          {...rest}
          to={{
            pathname: "/login",
          }}
        />;
    return renderComponent;
}

const App: React.FC = () => {
    let isLoggedIn = userService.isUserLoggedIn();

    useEffect(() => {
        isLoggedIn = userService.isUserLoggedIn();
    },[isLoggedIn]);

    return (
      <IonApp>
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
                <ProtectedRoute exact path="/tab2" component={Tab2} />
                <ProtectedRoute path="/tab3" component={Tab3} />
                <ProtectedRoute exact path="/home" component={HomeScreen} />
                <ProtectedRoute exact path="/new" component={() => <NewDateScreen />} />
                <ProtectedRoute exact path="/itinerary/:id" component={ItineraryView} />
                <ProtectedRoute exact path="/builder/:id" component={Builder} />
                <Route exact path="/login" component={LoginScreen} />
                <Route exact path="/signup" component={SignupScreen} />
                <Redirect exact path="/" to="/home" />
            </IonRouterOutlet>
            <IonTabBar slot="bottom" hidden={!isLoggedIn}>
              <IonTabButton tab="tab1" href="/home">
                <IonIcon icon={wineOutline} />
              </IonTabButton>
              <IonTabButton tab="tab2" href="/tab2">
                <IonIcon icon={peopleOutline} />
              </IonTabButton>
              <IonTabButton tab="tab3" href="/tab3">
                <IonIcon icon={cloudyNightOutline} />
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      </IonApp>
    );
};

export default App;
