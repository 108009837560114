import { Wrapper, Status } from "@googlemaps/react-wrapper";
import React, {useEffect, useState} from 'react';

const MyMapComponent: any = (props: any) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = useState(null);
    const { center, zoom } = props;
    useEffect(() => {
        const map = new (window as any).google.maps.Map(ref.current, {
            center,
            zoom,
            disableDefaultUI: true,
            // gestureHandling: 'none',
        });
        const geocoder = new (window as any).google.maps.Geocoder();
        geocoder.geocode({'address': "New York City"}, (results: any, status: any) => {
            if (status === 'OK') {
                const location = results[0].geometry.location;
                (map as any).panTo(location);
                (map as any).setZoom(15);
            } else {
                alert('Geocode was not successful for the following reason: ' + status);
            }
        });
        if (props.onLoad) {
            props.onLoad(map);
        }
    }, []);
    return (
        <div ref={ref} id="map" style={{ width: props.width, height: props.height }} />
    );
};

const MapComponent: any = (props: any) => {
    const render = (status: Status) => {
        return <h1>{status}</h1>;
    };
    const { lat, lng, width = '100%', height = '100%' } = props;
    const ref = React.useRef<HTMLDivElement>(null);
    const center = { lat: -34.397, lng: 150.644 };
    const zoom = 4;

    const mapProps = {
        ...props
    };
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Wrapper apiKey={"AIzaSyBewZha6vKav_X8ebBq2_PfODgYGXJYbys"} render={render} libraries={["places"]}>
                <MyMapComponent
                    width={width}
                    height={height}
                    center={props.center}
                    zoom={zoom}
                    onLoad={props.onLoad}
                ></MyMapComponent>
            </Wrapper>
        </div>
    );
};

export default MapComponent;