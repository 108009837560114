import {
    IonContent,
    IonPage,
    IonToolbar,
    IonTitle,
    IonRow,
    IonCol,
    IonGrid,
    IonHeader,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonInput,
    IonLabel, IonButton, IonList, IonItem, IonCardContent,
    IonSpinner,
} from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router";
import userService from "../services/user.service";
import {Redirect} from "react-router-dom";

const LoginScreen:any = (props: any) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const history = useHistory();

    const handleLogin = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        await userService.login(username, password);
        setIsLoggedIn(true);
        setLoading(false);
    };

    const loginCard = <IonCard>
        <IonCardContent>
            <form onSubmit={(e) => handleLogin(e)}>
                <IonList>
                    <IonItem>
                        <IonLabel position="stacked">Email</IonLabel>
                        <IonInput
                            required
                            placeholder="Username"
                            type="email"
                            onInput={(e: any) => setUsername(e.target.value)} />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Password</IonLabel>
                        <IonInput
                            required
                            placeholder="Password"
                            type="password"
                            onInput={(e: any) => setPassword(e.target.value)}
                        />
                    </IonItem>
                </IonList>
                <h6 style={{fontSize: '14px', textAlign: 'right'}}>Forgot your password?</h6>
                <IonButton
                    style={{ marginTop: '40px'}}
                    expand="full"
                    type="submit"
                >Login</IonButton>
            </form>
        </IonCardContent>
    </IonCard>

    return (
        isLoggedIn ? <Redirect to="/home"/> :
        <IonPage>
            <IonContent>
                <IonGrid style={{ height: '100%' }}>
                    <IonRow style={{ height: '100%' }} className="ion-justify-content-center ion-align-items-center">
                        <IonCol size="12" sizeSm="6">
                            <div>
                                <img style={{ width: '200px', margin: '0 auto', display: 'block' }} src="../assets/logo.png"/>
                            </div>
                            {
                                !loading && <div>
                                    { loginCard }
                                    <IonCard>
                                        <IonCardContent>
                                            <h6>Don't have an account? Create one now!</h6>
                                            <IonButton
                                                style={{ marginTop: '25px'}}
                                                color="secondary"
                                                expand="full"
                                                href="/signup"
                                            >Sign up</IonButton>
                                        </IonCardContent>
                                    </IonCard>
                                </div>
                            }
                            {
                                loading &&
                                <div style={{ textAlign: 'center', paddingTop: '50px'}}>
                                    <IonSpinner name="crescent" />
                                </div>
                            }
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default LoginScreen;