import axios from "axios";
import { IItinerary, IItineraryActivityType } from "../model/IItinerary";
import { getJWT } from './storage.service';

const apiRequest = async (url: string, config: any = {}) => {
    return axios(url, {
        headers: {
            Authorization: `bearer ${getJWT()}`,
            ...config.headers,
        } as any,
        ...config
    });
}

export const getAllItineraries = async () : Promise<IItinerary[]> => {
    const res = await apiRequest('/api/v1/itinerary');
    return res.data;
};

export const getItinerary = async (id: string) : Promise<IItinerary> => {
    const res = await apiRequest(`/api/v1/itinerary/${id}`);
    return res.data;
};

export const getSuggestions = async (query: string): Promise<any> => {
    const res = await apiRequest(`/api/v1/suggestions/`);
    return res.data;
}

export const createItinerary = async (itinerary: any) => {
    const res = await apiRequest('/api/v1/itinerary', {
        method: 'POST',
        data: {
            ...itinerary
        }
    });
    return res.data;
};

export const deleteItinerary = async (id: string) => {

};

export const updateItinerary = async (itinerary: string) : Promise<IItinerary> => {
    return {} as IItinerary;
};

export const getItineraryOptions = async (category: string) => {

};

export default {
    getAllItineraries,
    getItinerary,
    createItinerary,
    deleteItinerary,
    updateItinerary,
    getItineraryOptions,
    getSuggestions,
};