import {
    IonContent,
    IonPage,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonGrid,
    IonButton,
    IonToolbar, IonTitle, IonHeader, IonSpinner, useIonRouter
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import LocationStep  from "../../components/NewDateSteps/LocationStep";
import {compass} from "ionicons/icons";
import DateTimeStep from "../../components/NewDateSteps/DateTimeStep";
import PersonalityStep from "../../components/NewDateSteps/PersonalityStep";
import InterestsStep from "../../components/NewDateSteps/InterestsStep";
import FoodsStep from "../../components/NewDateSteps/FoodsStep";
import {createItinerary} from "../../services/itinerary.service";

const DATE_STEPS = {
    "LOCATION": "LOCATION",
    "DATE_TIME": "DATE_TIME",
    "PERSONALITY": "PERSONALITY",
    "INTERESTS": "INTERESTS",
    "FOODS": "FOODS",
};

const initialDateMetadata = {
    name: "Itinerary",
    location: {},
    startDate: null,
    personality: '',
    rankedActivities: new Map(),
    rankedFoods: new Map(),
};

const NewDateScreen : React.FC = () => {
    const { push } = useIonRouter();
    const newDateSteps = Object.keys(DATE_STEPS);
    const [currentStep, setCurrentStep] = useState(DATE_STEPS.LOCATION);
    const [itinerary, setItinerary] = useState(initialDateMetadata);
    const [isSaving, setIsSaving] = useState(false);


    const currentStepNumber = () => {
        return Object.keys(DATE_STEPS).indexOf(currentStep) + 1;
    };

    const totalSteps = newDateSteps.length;
    const isLastStep = currentStepNumber() === totalSteps;

    const handleUpdate = (key: string, value: any) => {
        // @ts-ignore
        itinerary[key] = value;
        setItinerary({ ...itinerary });
    };

    const handleUpdateRankedItem = (key: string, name: string, value: number) => {
        // @ts-ignore
        itinerary[key].set(name, value);
        setItinerary({ ...itinerary });
    }

    const withCard = (component: any) => (
        <IonCard className="animate__animated animate__backInRight animate__fast" key={currentStep}>
            <IonCardContent>
                {component}
                <IonButton expand="block" onClick={() => setNextStep()}>{ isLastStep ? 'Finish' : 'Next'}</IonButton>
            </IonCardContent>
        </IonCard>
    );

    const dateStepToComponentMap = {
        [DATE_STEPS.LOCATION]: () => withCard(
            <LocationStep
                name="Location"
                handleUpdate={(value: any) => handleUpdate('location', value)}
            />),
        [DATE_STEPS.DATE_TIME]: () => withCard(
            <DateTimeStep
                handleUpdate={(value: any) => handleUpdate('startDate', value)}
                name="Date Time"
            />),
        [DATE_STEPS.PERSONALITY]: () => withCard(
            <PersonalityStep
                handleUpdate={(value: any) => handleUpdate('personality', value)}
                name="Personality"
            />),
        [DATE_STEPS.INTERESTS]: () => withCard(
            <InterestsStep
                handleUpdate={(value: any) => handleUpdateRankedItem('rankedActivities', value.name, value.rank)}
                name="Interests"
            />),
        [DATE_STEPS.FOODS]: () => withCard(
            <FoodsStep
                handleUpdate={(value: any) => handleUpdateRankedItem('rankedFoods', value.name, value.rank)}
                name="Foods"
            />),
    };

    const setNextStep = () => {
        const currentStepIndex = Object.keys(DATE_STEPS).indexOf(currentStep);
        const lastStepIndex = newDateSteps.length - 1;

        if (isLastStep) {
            // Submit to next part
            submitItinerary();
        } else {
            setCurrentStep(currentStepIndex >= lastStepIndex ? newDateSteps[lastStepIndex] : newDateSteps[currentStepIndex + 1]);
        }
    };

    const submitItinerary = async () => {
        setIsSaving(true);
        try {
            const payload = transformItineraryToPayload(itinerary);
            const res = await createItinerary(payload);
            if (res.id) {
                push(`builder/${res.id}`);
            } else {
                throw new Error('no id returned');
            }
        } catch (e) {
            // Show error screen
        }
    }

    const transformItineraryToPayload = (itinerary: any) => {
        const { rankedFoods, rankedActivities, ...other } = itinerary;
        const payload = { ...other, rankedFoods: [], rankedActivities: [] };

        rankedFoods.forEach((value: number, key: string) => {
            // @ts-ignore
            payload.rankedFoods.push({
                [key]: value
            });
        });

        rankedActivities.forEach((value: number, key: string) => {
            // @ts-ignore
            payload.rankedActivities.push({
                [key]: value
            });
        });

        payload.startDate = new Date(payload.startDate).toISOString();

        console.log(payload);
        return payload;
    };


    /*
        debug util:
        {JSON.stringify(itinerary, function replacer(key, value) {
            if(value instanceof Map) {
                return Array.from(value.entries());
            } else {
                return value;
            }
        })}
     */

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar style={{ paddingBottom: '10px', paddingTop: '10px'}}>
                    <IonTitle slot="start">New Date</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid style={{ maxWidth: "960px" }}>
                    <IonRow className="ion-justify-content-center ion-align-items-center">
                        {
                            !isSaving &&
                            <IonCol size="12" sizeSm="6">
                                <div style={{ textAlign: 'center' }}>
                                    <h4>Step {currentStepNumber()} of {totalSteps}</h4>
                                </div>
                                {dateStepToComponentMap[currentStep]()}
                            </IonCol>
                        }
                        {
                            isSaving &&
                            <IonCol size="12" sizeSm="6">
                                <div>
                                    <IonCard className="animate__animated animate__backInRight animate__fast">
                                        <IonCardContent className="new-date__saving">
                                            <h1>Saving</h1>
                                            <IonSpinner></IonSpinner>
                                        </IonCardContent>
                                    </IonCard>
                                </div>
                            </IonCol>
                        }
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default NewDateScreen;