import {IonCard, IonCardContent, IonCol, IonRow} from "@ionic/react";

const NightcapBuilderContainer: any = (props: any) => {
    const items = new Array(4).fill(5);
    return (
        <div style={{ padding: '10px', marginBottom: '40px'}}>
            <div style={{ padding: '10px' }}>
                <h1>Nightcap</h1>
                <h4>Suggestions</h4>
            </div>
            <IonRow>
                {
                    items.map(() => (
                        <IonCol size-md={4}>
                            <IonCard>
                                <img src="https://via.placeholder.com/150x150" style={{ width: '100%', textAlign: 'left'}}/>
                                <IonCardContent>
                                    <h2>Gift Basket</h2>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    ))
                }
            </IonRow>
        </div>
    );
};

export default NightcapBuilderContainer;