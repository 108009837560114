import {useEffect, useState} from "react";
import {getSuggestions} from "../../services/itinerary.service";
import {IonCard, IonCardContent, IonCol, IonRow} from "@ionic/react";

const SuggestionsContainer = (props: any) => {
    const { showMore } = props;
    const [suggestions, setSuggestions] = useState([]);

    useEffect(() => {
        getSuggestions('middleman').then(result => {
            setSuggestions(result);
        })
    }, []);

    const columnSize = showMore ? 2 : 6;

    return (
        <div style={{ padding: '10px', marginBottom: '40px'}}>
            <div style={{ padding: '10px' }}>
                <h1>Activities</h1>
                <h4>Suggestions</h4>
            </div>
            <IonRow>
                {
                    suggestions && suggestions.map((suggestion: any) => (
                        <IonCol size-md={columnSize}>
                            <IonCard onClick={() => props.onSelection(suggestion)}>
                                <div style={{ display: 'flex' }}>
                                    <img src={suggestion.photos[0]} style={{ textAlign: 'left', width: '100px', height: '100px', objectFit: 'cover', marginRight: '20px'}}/>
                                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                        <p><b>{ suggestion.name }</b></p>
                                        <img src={`assets/icon/ratings/rating${suggestion.rating.toString()}.png`} style={{ maxWidth: '100px'}}/>
                                        <img src={'assets/icon/yelp_logo.png'} style={{ maxWidth: '50px'}} />
                                    </div>
                                </div>
                            </IonCard>
                        </IonCol>
                    ))
                }
            </IonRow>
        </div>
    );
};

export default SuggestionsContainer;