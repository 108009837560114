import {
    IonCard,
    IonCardContent, IonCardHeader,
} from "@ionic/react";
import {useEffect, useState} from "react";
import {getItinerary} from "../../services/itinerary.service";

type IItineraryContainerProps = {
    itinerary?: object;
    id?: string;
}
const ItineraryItem = (props: any) => {
    const activity = props.activity;
    return (
        <IonCard>
            <div style={{display: "flex"}}>
                <img src="https://via.placeholder.com/150x150" style={{textAlign: 'left'}}/>
                <IonCardContent>
                    <h2>{ activity.name }</h2>
                    <p>{ activity.description }</p>
                </IonCardContent>
            </div>
        </IonCard>
    );
};

const EmptyItinerary = (props: any) => {
    return (
        <div style={{ padding: 20, display: 'flex' }}>
            <h6 style={{ flex: 1, textAlign: 'center', paddingBottom: 30, paddingTop: 30, borderWidth: 1, borderRadius: 10, borderColor: 'black', borderStyle: 'dashed' }}>Itinerary is empty. Add a new activity.</h6>
        </div>
    )
}

const ItineraryContainer: any = (props: IItineraryContainerProps) => {
    const { itinerary } = props as any;

    const displayComponent = itinerary.activityList && itinerary.activityList.length > 0 ?
            <IonCardContent>
                {
                    itinerary && itinerary.activityList && itinerary.activityList.map((activity: any) => {
                        return <ItineraryItem activity={activity}/>;
                    })
                }
            </IonCardContent> :
            <EmptyItinerary />;

    return (
        <div>
            <IonCard>
                <IonCardHeader>
                    <h2>Itinerary</h2>
                    <h5>{ new Date(itinerary.startDate).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }</h5>
                </IonCardHeader>
                {displayComponent}
            </IonCard>
        </div>
    );
};

ItineraryContainer.defaultProps = {

};

export default ItineraryContainer;