import {
    IonCol,
    IonContent,
    IonGrid,
    IonRow,
    IonPage,
    IonCard,
    IonCardContent,
    IonItem,
    IonLabel,
    IonCardHeader,
    IonIcon,
    IonToolbar,
    IonButtons,
    IonButton,
    IonTitle,
    IonHeader,
} from "@ionic/react";
import {
    peopleOutline,
    close,
} from "ionicons/icons";
import MapComponent from "../../components/MapComponent";
import ItineraryContainer from "../../components/ItineraryContainer/ItineraryContainer";
import ItineraryMenu from "../../components/ItineraryMenu/ItineraryMenu";
import NightcapBuilderContainer from "../../components/NightcapBuilderContainer/NightcapBuilderContainer";
import SuggestionsContainer from "../../components/SuggestionsContainer/SuggestionsContainer";
import {useEffect, useState} from "react";
import {getItinerary} from "../../services/itinerary.service";

const Builder = (props: any) => {
    const [presentationSelection, setPresentationSelection] = useState() as any;
    const [itinerary, setItinerary] = useState({});
    const itineraryId = props.computedMatch.params.id;

    useEffect(() => {
        getItinerary(itineraryId).then(itinerary => setItinerary(itinerary));
    }, []);

    const showMore = false;

    const columnSize = showMore ? 10 : 6;

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <IonButtons slot="primary">
                        <IonButton>
                            <IonIcon slot="icon-only" icon={peopleOutline} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle slot="start"><img style={{ width: '100px'}} src="assets/logo.png"/></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid style={{ height: '100%', '--ion-grid-column-padding': '0px', '--ion-grid-padding': '0px' }} >
                    <IonRow style={{ height: '100%'}}>
                        <IonCol size-md="2" size-sm="3" style={{ height: '100%', borderRight: '1px solid', borderColor: '#d7d8da'}}>
                            <ItineraryMenu />
                        </IonCol>
                        <IonCol size-md={columnSize} style={{ height: '100%', overflowY: "scroll"}}>
                            <section id="suggestions">
                                <SuggestionsContainer showMore={showMore} onSelection={(sel: any) => setPresentationSelection(sel)}/>
                            </section>
                            <section id="itinerary">
                                <ItineraryContainer itinerary={itinerary} />
                            </section>
                            <section id="nightcap">
                                <NightcapBuilderContainer />
                            </section>
                        </IonCol>
                        <IonCol size-md={4} style={{ height: '100%', position: 'relative' }}>
                            <MapComponent width="100%" height="100%" />
                            {
                                presentationSelection &&
                                <div className="animate__animated animate__slideInUp" style={{ height: '400px', width: '100%', position: 'absolute', bottom: 0, backgroundColor: '#F8F8F8', zIndex: 10000, borderRadius: '30px 30px 0px 0px', padding: 40, overflowY: 'scroll', boxShadow: "rgba(0, 0, 0, 0.5) 0px 0px 2px" }}>
                                    <div onClick={() => setPresentationSelection()} style={{ position: 'absolute', top: 20, right: 20}}>
                                        <IonIcon icon={close} size="large"/>
                                    </div>
                                    <div>
                                        <h3>{presentationSelection.name}</h3>
                                        <p>
                                            {`${presentationSelection.location.address.address1} ${presentationSelection.location.address.address2} ${presentationSelection.location.address.city} ${presentationSelection.location.address.state}`}
                                        </p>
                                    </div>
                                    <div>
                                        <img src={`assets/icon/ratings/rating${presentationSelection.rating.toString()}.png`} style={{ maxWidth: '100px'}}/>
                                        <img src={'assets/icon/yelp_logo.png'} style={{ maxWidth: '50px'}} />
                                    </div>
                                    <IonButton expand="block">Add to itinerary</IonButton>
                                </div>
                            }
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Builder;