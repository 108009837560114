import {
    IonCard, IonCardTitle,
    IonDatetime,
    IonCardHeader, IonIcon,IonCardContent,
} from '@ionic/react';
import { bodyOutline, peopleOutline, airplane } from "ionicons/icons";
import {useState} from "react";

const PersonalityStep: any = (props: any) => {
    const [selection, setSelection] = useState('');
    const { handleUpdate } = props;

    const getClassName = (value: string) => selection === value ? 'personality-card personality-card__selected' : 'personality-card';

    const onUpdate = (value: string) => {
        setSelection(value);
        handleUpdate(value);
    };

    return (
        <div>
            <div>
                <h1>What is your personality type?</h1>
                <p>We can choose places that can match the type of person you are.</p>

                <div>
                    <IonCard className={getClassName('INTROVERT')} onClick={() => onUpdate('INTROVERT')}>
                        <IonCardHeader>
                            <h3 style={{ fontSize: '1.5rem'}}><IonIcon icon={bodyOutline} /> Introverted</h3>
                        </IonCardHeader>
                    </IonCard>

                    <IonCard className={getClassName('EXTROVERT')} onClick={() => onUpdate('EXTROVERT')}>
                        <IonCardHeader>
                            <h3 style={{ fontSize: '1.5rem'}}><IonIcon icon={peopleOutline} /> Extroverted </h3>
                        </IonCardHeader>
                    </IonCard>

                    <IonCard className={getClassName('AMBIVERT')} onClick={() => onUpdate('AMBIVERT')}>
                        <IonCardHeader>
                            <h3 style={{ fontSize: '1.5rem'}}><IonIcon icon={airplane} /> Ambiverted</h3>
                        </IonCardHeader>
                    </IonCard>
                </div>
            </div>
        </div>
    );
};

export default PersonalityStep;