import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    useIonRouter,
    IonLoading,
    IonSpinner,
} from '@ionic/react';
import { addCircleOutline } from 'ionicons/icons';

import ExploreContainer from '../components/ExploreContainer';
import DateListContainer from "../components/DateListContainer/DateListContainer";
import './Tab1.css';
import {useEffect, useState} from "react";
import itineraryService from '../services/itinerary.service';

const ItineraryLoadingStates = {
    SUCCESS: "SUCCESS",
    LOADING: "LOADING",
    FAILURE: "FAILURE"
};

const HomeScreen: React.FC = () => {
    const { push } = useIonRouter();
    const [itineraryList, setItineraryList] = useState([]);
    const [itineraryLoadingState, setItineraryLoadingState] = useState(ItineraryLoadingStates.LOADING);
    const isLoading = itineraryLoadingState === ItineraryLoadingStates.LOADING;
    const didSucceed = itineraryLoadingState === ItineraryLoadingStates.SUCCESS;

    useEffect(() => {
        if (itineraryList.length <= 0 || isLoading) {
            fetchItineraryList();
        }
    }, []);

    const fetchItineraryList = async () => {
        try {
            const result = await itineraryService.getAllItineraries();
            console.log(result);
            setItineraryLoadingState(ItineraryLoadingStates.SUCCESS);
            setItineraryList(result as any);
        } catch (e) {
            console.log('Failed to fetch itinerary list', e);
            setItineraryLoadingState(ItineraryLoadingStates.FAILURE);
        }
    };
    return (
    <IonPage>
      <IonHeader>
        <IonToolbar style={{ paddingBottom: '10px', paddingTop: '10px'}}>
            <IonButtons slot="primary">
                <IonButton onClick={() => push('/new')}>
                    <IonIcon slot="icon-only" icon={addCircleOutline} />
                </IonButton>
            </IonButtons>
            <IonTitle slot="start"><img style={{ width: '100px'}} src="assets/logo.png"/></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {
          !didSucceed && !isLoading &&
          <div style={{ textAlign: 'center', paddingTop: '50px'}}>
              <h2>Unable to load your itineraries.</h2>
              <p>Try reloading the page again</p>
          </div>
        }
        {
            didSucceed && itineraryList.length > 0 &&
            <DateListContainer itineraryList={itineraryList} />
        }
        {
            didSucceed && itineraryList.length === 0 &&
            <div style={{ textAlign: 'center', paddingTop: '50px'}}>
                <h2>No itineraries yet!</h2>
                <p>Create one to get started</p>
            </div>
        }
        {
            isLoading &&
            <div style={{ textAlign: 'center', paddingTop: '50px'}}>
                <IonSpinner name="crescent" />
            </div>
        }
      </IonContent>
    </IonPage>
  );
};

export default HomeScreen;
