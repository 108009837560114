import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    useIonRouter,
} from '@ionic/react';
import {PropsWithChildren, useState} from 'react';
import { personCircle, search, helpCircle, star, create, ellipsisHorizontal, ellipsisVertical } from 'ionicons/icons';
import DateInfoCard from "../DateInfoCard/DateInfoCard";
import {IItinerary} from "../../model/IItinerary";

const DateListContainer : any = (props: any) => {
    const { push } = useIonRouter();
    const [selectedView, updateSelectedVue] = useState("upcoming");

    const oldDatesList = props.itineraryList.filter((dateItem: any) => dateItem.startDate < new Date());
    const futureDatesList = props.itineraryList;

    const getDateInfoCardsForList = (list: any) => list.map((itinerary: IItinerary) => {
        return (
            <IonCol key={itinerary.id} size-md="4" size-sm="12" size-xs="12" onClick={() => push('/builder/' + itinerary.id)}>
                <DateInfoCard itinerary={itinerary} key={itinerary.id} />
            </IonCol>
        );
    })

    const upcomingDatesList = getDateInfoCardsForList(futureDatesList);
    const previousDatesList = getDateInfoCardsForList(oldDatesList);

    const listToShowUser = selectedView === "upcoming" ? upcomingDatesList : previousDatesList;

    return (
      <IonContent>
        <IonGrid style={{ maxWidth: "960px" }}>
            {/*<IonSegment value={selectedView} onIonChange={(e: any) => updateSelectedVue(e.detail.value)}>*/}
            {/*    <IonSegmentButton value="upcoming" >*/}
            {/*        <IonLabel>Upcoming</IonLabel>*/}
            {/*    </IonSegmentButton>*/}
            {/*    <IonSegmentButton value="past">*/}
            {/*        <IonLabel>Past</IonLabel>*/}
            {/*    </IonSegmentButton>*/}
            {/*</IonSegment>*/}
            <IonRow style={{ marginTop: '40px'}}>
                <IonCol>
                    <h2 style={{ marginTop: '0px'}}>Upcoming and current dates</h2>
                </IonCol>
                <IonCol>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                        <IonButton color="warning" onClick={() => push('/new')}>Create new itinerary</IonButton>
                    </div>
                </IonCol>
            </IonRow>
            <IonRow>
                { listToShowUser }
            </IonRow>
        </IonGrid>
      </IonContent>
    );
};

export default DateListContainer;