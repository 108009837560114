import {
    IonItem,
    IonIcon,
    IonLabel,
    IonButton,
} from "@ionic/react";
import {
    bodyOutline,
    calendarNumberOutline,
    cloudyNightOutline,
    restaurantOutline
} from "ionicons/icons";

const ItineraryMenu = () => {
    const setLocationHash = (location: string) => {
        window.location.hash = `#${location}`;
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <div>
                <IonItem button onClick={() => setLocationHash('suggestions')}>
                    <IonIcon icon={bodyOutline} slot="start" />
                    <IonLabel>
                        Activities
                    </IonLabel>
                </IonItem>
                <IonItem button>
                    <IonIcon icon={restaurantOutline} slot="start" />
                    <IonLabel>
                        Restaurants
                    </IonLabel>
                </IonItem>
                <IonItem button onClick={() => setLocationHash('itinerary')}>
                    <IonIcon icon={calendarNumberOutline} slot="start" />
                    <IonLabel>
                        Itinerary
                    </IonLabel>
                </IonItem>
                <IonItem button onClick={() => setLocationHash('nightcap')}>
                    <IonIcon icon={cloudyNightOutline} slot="start" />
                    <IonLabel>
                        Nightcap
                    </IonLabel>
                </IonItem>
            </div>

            <IonButton color="warning" expand="full" style={{ marginTop: 'auto' }}>Dating Mode</IonButton>
        </div>
    );
};

export default ItineraryMenu;