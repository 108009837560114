import axios from 'axios';
import storageService from './storage.service';

export const isUserLoggedIn = () => Boolean(storageService.getJWT()) || false;

export const login = async (username: string, password: string) => {
    try {
        const res = await axios.post('/api/v1/auth/login', {
            username,
            password
        });
        console.log(res);
        storageService.setJWT((res as any).data.access_token);
    } catch (e) {
        console.log(e);
        throw new Error("Failed to login");
    }
}

export const registerUser = async (name: string, email: string, password: string ) => {
   try {
        const res = await axios.post('/api/v1/user', {
            email,
            password,
            name
        });
        storageService.setJWT((res as any).data.access_token);
   } catch (e) {
       // Handle request failures here
       console.log(e);
        throw new Error("Failed to signup");
   }

};

export const updateUser = async (user: any) => {
    try {

    } catch (e) {

    }
};

export const logout = async () => {
    storageService.removeJWT();
};


export default {
    login,
    registerUser,
    updateUser,
    logout,
    isUserLoggedIn,
};