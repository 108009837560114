import * as d3 from 'd3';
import React, {useEffect} from "react";

const InterestsStep: any = (props: any) => {
    const ref = React.useRef<HTMLDivElement>(null);
    let activities = [
        'Movies',
        'Eating out',
        'Music festivals',
        'Sports',
        'Walking tours',
        'Museums',
        'Parks',
        'Zoos/Aquariums',
        'Botanical garden',
        'Amusement parks',
        'Arcades'
    ];
    const createSimulation = (graph: any) => {
        // @ts-ignore
        return d3.forceSimulation()
            .nodes(graph.nodes)
            .force('charge', d3.forceManyBody()
                .strength(1000)
                .theta(0.8)
                .distanceMax(50)
            )
            .force('collide', d3.forceCollide()
                .radius(function(d: any) {
                    console.log(d3.select(d).node().radius);
                    return 80;
                })
                .iterations(10)
            )
            .force('center', d3.forceCenter(900 / 2, 800 / 2))
            // @ts-ignore
            .force('x', d3.forceX([900/2]).strength(0.01))
            // @ts-ignore
            .force('y', d3.forceY([500/2]).strength(0.01))
            .force("link", d3.forceLink().distance((d: any) => 100).strength(10));
    };

    const nodes = activities.map((item: any, i: number) => ({
        id: i + 1,
        name: item,
        group: 1
    }));

    const sources = activities.map((item: any, i: any) => ({
        source: i + 1,
        target: 0,
    }));

    const initialize = () => {
        const element: any = ref.current;
        const graph = {
            nodes,
            links: sources
        };

        const handleSelection = (item: any, score: any) => {
            console.log({ item, score });
            props.handleUpdate({ name: item, rank: score });
        };

        const simulation = createSimulation(graph);

        const svg = d3.select(element)
            .append('svg')
            .attr('viewBox', '0 0 900 800')

        const link = svg.append("g")
            .style("stroke", "")
            .selectAll("line")
            .data(graph.links)
            .enter().append("line");

        const container = svg.append("g")
            .attr("class", "nodes")
            .selectAll("g")
            .data(graph.nodes)
            .enter().append("g");

        // @ts-ignore
        // @ts-ignore
        const node = container
            .attr('class', 'circle-container')
            .append('circle')
            .attr('class', 'circle')
            .attr("r", 80)
            .style('fill', () => "rgb(255,204,0)")
            .style('stroke', "black")
            .attr('stage', 0)
            .on('dblclick', function (event: any, d: any) {
                event.stopPropagation();
                // @ts-ignore
                const selectedElement = d3.select(this);
                const stageMap = [80, 100, 120];
                const scoreMap = [0, 0.5, 1];
                const stage = Number(selectedElement.attr('stage')) + 1;
                const radius = stageMap[stage];
                const score = scoreMap[stage];
                selectedElement.attr('stage', stage === 2 ? 0 : stage);
                selectedElement.transition().attr("r", radius);
                simulation.alphaTarget(0.5).restart();
                // @ts-ignore
                d3.select(this.parentNode).raise();

                console.log(simulation.nodes()[0]);

                // @ts-ignore
                handleSelection(d.name, score);
            })
            // @ts-ignore
            .call(d3.drag()
                .on("start", (event: any, d: any) => { dragStarted(event, d, simulation) })
                .on("drag", (event: any, d: any) => { dragged(event, d, simulation) })
                .on("end", (event: any, d: any) => { dragEnded(event, d, simulation); simulation.restart() })
            )

        const label = container
            .append("text")
            .attr("text-anchor", "middle")
            .attr('font-size', "25px")
            .attr('word-wrap', "wrap")
            .text(function(d: any) {
                // @ts-ignore
                return d.name;
            });

        // @ts-ignore
        simulation.nodes(graph.nodes).on("tick", ticked);

        function ticked() {
            link.attr("x1", function(d: any) {
                // @ts-ignore
                return d.source.x;
            })
                .attr("y1", function(d: any) {
                    // @ts-ignore
                    return d.source.y;
                })
                .attr("x2", function(d: any) {
                    // @ts-ignore
                    return d.target.x;
                })
                .attr("y2", function(d: any) {
                    // @ts-ignore
                    return d.target.y;
                });

            node.attr("cx", function (d: any) {
                // @ts-ignore
                return d.x;
            })
                .attr("cy", function(d: any) {
                    // @ts-ignore
                    return d.y;
                });

            label.attr("x", function(d: any) {
                // @ts-ignore
                return d.x
            })
                .attr("y", function (d: any) {
                    // @ts-ignore
                    return d.y;
                });
        }
    }

    function dragStarted(event: any, d: any, simulation: any) {
        if (!event.active) simulation.alphaTarget(0.3).restart()
        d.fx = d.x
        d.fy = d.y
    }

    function dragged(event: any, d: any, simulation: any) {
        d.fx = event.x
        d.fy = event.y
    }

    function dragEnded(event: any, d: any, simulation: any) {
        d.fx = null
        d.fy = null
        if (!event.active) simulation.alphaTarget(0);
    }

    useEffect(() => {
        initialize();
    }, []);

    return (
        <div>
            <div>
                <h1>4. Select your interests you like</h1>
                <p>Double tap a bubble, the more you press the more you like it</p>
            </div>
            <div ref={ref}>
            </div>
        </div>
    );
};

export default InterestsStep;