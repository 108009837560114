import {IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonImg, IonText} from '@ionic/react';

const DateInfoCard : any = (props: any) => {
    const dateString = new Date(props.itinerary.startDate).toLocaleDateString();
    const googleMapBaseUrl = 'https://maps.googleapis.com/maps/api/staticmap?';
    const getImageUrl = () => {
        if (props.itinerary.location && props.itinerary.location.addressComplete) {
            const address = props.itinerary.location.addressComplete;
           return `${googleMapBaseUrl}center=${address}&zoom=16&size=600x300&markers=color:red|size:mid|scale:2|${address}&key=AIzaSyBewZha6vKav_X8ebBq2_PfODgYGXJYbys`
        } else {
            return `${googleMapBaseUrl}center=New York City&zoom=13&size=600x300&key=AIzaSyBewZha6vKav_X8ebBq2_PfODgYGXJYbys`
        }
    }
    return (
        <IonCard>
            <img src={getImageUrl()} style={{ width: "100%"}}/>

            <IonCardContent>
                <p>NYC { dateString }</p>
                <img src="https://via.placeholder.com/50" style={{ width: "25px", borderRadius: "100px" }}/>
            </IonCardContent>
        </IonCard>
    );
};

export default DateInfoCard;