import {
    IonPage,
    IonContent,
    IonGrid,
    IonRow, IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonTitle,
    IonHeader,
    IonCol,
    IonCardContent,
    IonCard, IonCardHeader,
} from "@ionic/react";
import MapComponent from "../../components/MapComponent";
import { partlySunny } from "ionicons/icons";

const ItineraryView: any = (props: any) => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar style={{ paddingBottom: '10px', paddingTop: '10px'}}>
                    <IonTitle slot="start">Itinerary</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid>
                    <IonRow className="ion-justify-content-center ion-align-items-center">
                        <IonCol size="12" sizeSm="6">
                            <div style={{ textAlign: 'center'}}>
                                <h6><IonIcon icon={partlySunny} /> 63° F</h6>
                            </div>
                            <IonCard>
                                <IonCardHeader>
                                    <h6>5:30PM</h6>
                                    <h4>Walk in the park</h4>
                                </IonCardHeader>
                                <IonCardContent>
                                    <MapComponent />
                                </IonCardContent>
                            </IonCard>

                            <IonCard>
                                <IonCardHeader>
                                    <h6>7:30PM</h6>
                                    <h4>Romantic dinner</h4>
                                </IonCardHeader>
                                <IonCardContent>
                                    <MapComponent center={{ lat: -73.9906741, lng: 40.7341209 }}/>
                                </IonCardContent>
                            </IonCard>

                            <IonCard>
                                <IonCardHeader>
                                    <h6>10:30PM</h6>
                                    <h4>Closing out the night</h4>
                                </IonCardHeader>
                                <IonCardContent>
                                    <MapComponent />
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default ItineraryView;