import { Wrapper, Status } from "@googlemaps/react-wrapper";
import React, {useEffect, useRef, useState} from 'react';
import {IonInput, IonLabel, IonItem, IonList} from "@ionic/react";
import MapComponent from "../MapComponent";

const LocationStep: any = (props: any) => {
    const [map, setMap] = useState(null) as any;
    const [marker, setMarker] = useState<google.maps.Marker>();
    const [place, setPlace] = useState() as any;
    const autoCompleteRef = useRef();
    const addressInputRef = useRef();
    const { handleUpdate } = props;
    const options = {
        componentRestrictions: { country: "us" },
        fields: ["formatted_address", "address_components", "geometry", "icon", "name"],
        types: ["establishment"]
    };
    const render = (status: Status) => {
        return <h1>{status}</h1>;
    };

    const center = { lat: -34.397, lng: 150.644 };
    const zoom = 4;

    const getAddressObjectFromAddressComponents = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
        const address = {};


        addressComponents?.forEach(component => {
           const componentType = component.types[0];
        });
    }

    const handlePlaceChanged = async () => {
        const place = await (autoCompleteRef.current as any).getPlace();
        // const map: any = mapRef.current;

        if (!place.geometry || !place.geometry.location) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            window.alert("No details available for input: '" + place.name + "'");
            return;
        }

        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
            map?.fitBounds(place.geometry.viewport);
        } else {
            map?.setCenter(place.geometry.location);
            map?.setZoom(17);
        }
        setPlace(place);
        handleUpdate({
            longitude: place.geometry.location.lng(),
            latitude: place.geometry.location.lat(),
            addressComplete: place.formatted_address
        });
    }

    useEffect(() => {
        if ((window as any).google && place) {
            if (marker) {
                marker.setPosition(place.geometry.location)
            } else {
                setMarker(new (window as any).google.maps.Marker({
                    map,
                    position: place.geometry.location,
                    draggable: false,
                }));
            }
        }
    }, [place]);

    useEffect(() => {
        if (window.google) {
            // @ts-ignore
            autoCompleteRef.current = new window.google.maps.places.Autocomplete(
                // @ts-ignore
                addressInputRef.current.childNodes[0],
                options
            );

            const marker = new (window as any).google.maps.Marker({
                map,
                position: center,
                draggable: false,
            });

            setMarker(marker);
            (autoCompleteRef.current as any).addListener("place_changed", handlePlaceChanged);
        }
    }, [map]);

    return (
      <div>
          <div>
              <h1>Select a location</h1>
              <p>This will be used to help search for activities for you date.</p>
          </div>
          <div>
              <IonItem>
                  <IonLabel className="ion-no-margin" position="stacked">Location</IonLabel>
                  <IonInput ref={addressInputRef as any} type="text" placeholder="Search for location" />
              </IonItem>
          </div>
          <Wrapper apiKey={"AIzaSyBewZha6vKav_X8ebBq2_PfODgYGXJYbys"} render={render} libraries={["places"]}>
              <div style={{ height: "400px", marginTop: "15px", marginBottom: "15px" }}>
                  <MapComponent onLoad={(m: any) => setMap(m)} center={center} zoom={zoom}></MapComponent>
              </div>
          </Wrapper>
      </div>
    );
};

export default LocationStep;